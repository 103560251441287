<template>
  <div class="page" id="Slide">
    <h1>Slide</h1>
  </div>
</template>

<script>
export default {
  name: "Slide"
}
</script>

<style scoped>

</style>
